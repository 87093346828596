import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTube = makeShortcode("YouTube");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "https://tailwindcss.com/docs/what-is-tailwind/"
      }}>{`Tailwind CSS`}</a>{` is winning at being a developer-friendly utility-first CSS framework and if you’re to looking upgrade or start out with a new project, you should give Tailwind a look for your CSS needs.`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/notrab/nextjs-tailwindcss-starter"
      }}>{`View code on GitHub`}</a></p>
    <p>{`If you follow me already you probably know I’m a huge fan of `}<a parentName="p" {...{
        "href": "https://nextjs.org"
      }}>{`Next.js`}</a>{` and if you’ve not heard of Next.js, it’s a lightweight React framework. 😍`}</p>
    <p>{`I recently tried to get both of the projects working together but it required way more confirmation than I liked. Thanks recent improvements, the `}<a parentName="p" {...{
        "href": "https://github.com/zeit/next-plugins/tree/master/packages/next-css"
      }}>{`next-css`}</a>{` library “just works” out of the box with Tailwind CSS.`}</p>
    <p>{`In this tutorial we’ll cover creating a new Next.js and configuring Tailwind with PostCSS.`}</p>
    <h2>{`Create a Next.js project`}</h2>
    <p>{`You’ll need to create a new directory for this project. Go ahead and do it now.`}</p>
    <h3>{`Initialize a new npm project`}</h3>
    <p>{`Open your Terminal, `}<inlineCode parentName="p">{`cd`}</inlineCode>{` into your newly created directory and initialize a new NPM project. We’ll provide the `}<inlineCode parentName="p">{`-y`}</inlineCode>{` flag to skip asking any questions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm init -y
`}</code></pre>
    <h3>{`Install dependencies`}</h3>
    <p>{`Next, we’ll use npm to install Next and React.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install --save next react react-dom
`}</code></pre>
    <h3>{`Install dev dependencies`}</h3>
    <p>{`We now need to install some dependencies which are used for compiling CSS, including Tailwind itself.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install --save-dev tailwindcss postcss-preset-env postcss
`}</code></pre>
    <h3>{`Configure npm scripts`}</h3>
    <p>{`Installing the dependencies above created a file called `}<inlineCode parentName="p">{`package.json`}</inlineCode>{`. This file contains a list of the project dependencies and we’ll now need to edit this file.`}</p>
    <p>{`Inside package.json you can replace scripts with the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`"scripts": {
  "dev": "next",
  "build": "next build",
  "start": "next start"
},
`}</code></pre>
    <h3>{`Create a page`}</h3>
    <p>{`Next.js by convention configures routes by filenames inside a `}<inlineCode parentName="p">{`pages`}</inlineCode>{` directory.`}</p>
    <p>{`Create a `}<inlineCode parentName="p">{`pages/index.js`}</inlineCode>{` file and add the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`export default () => (
  <div className="p-4 shadow rounded bg-white">
    <h1 className="text-purple-500 leading-normal">Next.js</h1>
    <p className="text-gray-500">with Tailwind CSS</p>
  </div>
);
`}</code></pre>
    <h3>{`Start the server`}</h3>
    <p>{`Now is a good time to check everything is in the right place and start the next server. Inside the terminal, you’ll want to run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run dev
`}</code></pre>
    <p>{`Next.js will tell you the application is running on `}<inlineCode parentName="p">{`http://localhost:3000`}</inlineCode>{` by default.`}</p>
    <p><img parentName="p" {...{
        "src": "https://thepracticaldev.s3.amazonaws.com/i/65uh1pokjz2l2n85yksq.png",
        "alt": "Next.js Terminal Output"
      }}></img></p>
    <h3>{`Configure and integrate Tailwind`}</h3>
    <p>{`Now the fun begins! If you’ve used Tailwind CSS before then the next steps will be familiar.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npx tailwindcss init
`}</code></pre>
    <p>{`By default this will create a file called `}<inlineCode parentName="p">{`tailwind.config.js`}</inlineCode>{` in your project root that looks a little like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// tailwind.config.js
module.exports = {
  future: {},
  purge: [],
  theme: {
    extend: {},
  },
  variants: {},
  plugins: [],
};
`}</code></pre>
    <p>{`This file alone doesn’t do anything. If you restart your application, you’ll see nothing has changed. We will next configure PostCSS and create our first stylesheet.`}</p>
    <h3>{`Configure PostCSS`}</h3>
    <p>{`We can now follow convention to configure PostCSS. Next will look for the file `}<inlineCode parentName="p">{`postcss.config.js`}</inlineCode>{` and load any defined plugins.`}</p>
    <p>{`Inside your project root, create the file `}<inlineCode parentName="p">{`postcss.config.js`}</inlineCode>{` and add the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`module.exports = {
  plugins: ["tailwindcss", "postcss-preset-env"],
};
`}</code></pre>
    <p>{`We’re doing a few things in this file:`}</p>
    <ul>
      <li parentName="ul">{`Invoking the tailwind library with our custom `}<inlineCode parentName="li">{`tailwind.config.js`}</inlineCode>{` file`}</li>
      <li parentName="ul">{`Invoking the `}<a parentName="li" {...{
          "href": "https://www.npmjs.com/package/postcss-preset-env"
        }}><inlineCode parentName="a">{`postcss-preset-env`}</inlineCode></a>{` module which is used to help browsers process our CSS, including autoprefixer.`}</li>
    </ul>
    <p><strong parentName="p">{`That’s it!`}</strong>{` 🎉`}</p>
    <h2>{`Using Tailwind`}</h2>
    <p>{`We’re now ready to `}<inlineCode parentName="p">{`@import`}</inlineCode>{` the tailwind css inside our stylesheet.`}</p>
    <h3>{`Import Tailwind into your CSS`}</h3>
    <p>{`Create a CSS file inside your project. I’ve created the directory and file `}<inlineCode parentName="p">{`styles/index.css`}</inlineCode>{` and added the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-blue-500 p-12;
}
`}</code></pre>
    <p>{`We’re using the @apply helper here to compose our own classes using Tailwind. This is magic. 🎩`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: If you wish to use Tailwind inside other css files and import those into your styles/index.css then you will need to update your CSS file to begin with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
`}</code></pre>
    <p><em parentName="p">{`You can read more about importing Tailwind over on their `}<a parentName="em" {...{
          "href": "https://tailwindcss.com/docs/installation#3-use-tailwind-in-your-css"
        }}>{`docs`}</a>{`.`}</em></p>
    <h3>{`Import your CSS with Next.js`}</h3>
    <p>{`Create a file at `}<inlineCode parentName="p">{`pages/_app.js`}</inlineCode>{`, or if you have one already, this is where you should import the stylesheet we created.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import "../styles/index.css";

export default function MyApp({ Component, pageProps }) {
  return <Component {...pageProps} />;
}
`}</code></pre>
    <h3>{`Start the Next.js app`}</h3>
    <p>{`We can now run `}<inlineCode parentName="p">{`npm run dev`}</inlineCode>{` to see our working Next.js + TailwindCSS example running at `}<a parentName="p" {...{
        "href": "http://localhost:3000/"
      }}><inlineCode parentName="a">{`http://localhost:3000`}</inlineCode></a>{`.`}</p>
    <h2>{`Links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/notrab/nextjs-tailwindcss-starter"
        }}>{`Code for this tutorial`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://youtu.be/Q5Z2U6a8T0g"
        }}>{`Video of this setup (uses autoprefixer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/zeit/next.js"
        }}>{`Next.js`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tailwindcss.com/"
        }}>{`Tailwind CSS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/notrab"
        }}>{`Me on Twitter`}</a></li>
    </ul>
    <p>{`I hope this helps ❤️`}</p>
    <p>{`Video (out of date):`}</p>
    <YouTube youTubeId="Q5Z2U6a8T0g" mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      